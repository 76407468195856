import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { SafeHtml } from '@thg-commerce/gravity-elements'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

import { HorizontalAlignment } from './AccordionContainer'

export const Container = styled.div<{
  horizontalAlignment: HorizontalAlignment
}>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.horizontalAlignment === HorizontalAlignment.ALTERNATE &&
    `${mq(props.theme.breakpointUtils.map, 'sm')} {
        flex-direction: row;
      }`}
  justify-content: space-evenly;
`

export const TextContainer = styled.div<{
  horizontalAlignment: HorizontalAlignment
}>`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.horizontalAlignment === HorizontalAlignment.ALTERNATE &&
    `${mq(props.theme.breakpointUtils.map, 'sm')} {flex-basis: 40%;`};
`

export const Title = styled(SafeHtml)<{
  horizontalAlignment: HorizontalAlignment
  fitTitles?: boolean
  addTitleRightMarginA11y?: boolean
}>`
  ${(props) =>
    Text(
      props.theme.widget.accordionContainer.title.font.entry,
      props.theme.widget.accordionContainer.title.font.style,
    )};
  margin-bottom: ${(props) => (props.fitTitles ? '0' : `${spacing(2)}`)};
  margin-right: 0;

  ${(props) =>
    props.horizontalAlignment === HorizontalAlignment.CENTER
      ? `text-align: center;`
      : `text-align: left;`}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-right: ${(props) =>
      props.addTitleRightMarginA11y ? `${spacing(10)}` : '0'};
  }
`
export const Subtitle = styled.div<{
  horizontalAlignment: HorizontalAlignment
  fitTitles?: boolean
}>`
  ${Text('bodyText', 'default')};
  margin-bottom: ${spacing(4)};
  margin-bottom: ${(props) =>
    props.fitTitles ? `${spacing(3)}` : `${spacing(4)}`};
  ${(props) =>
    props.horizontalAlignment === HorizontalAlignment.CENTER
      ? `text-align: center;`
      : `text-align: left;`}
`

export const AccordionItemContainer = styled.div<{
  horizontalAlignment: HorizontalAlignment
}>`
  width: 100%;
  ${(props) =>
    props.horizontalAlignment === HorizontalAlignment.ALTERNATE &&
    `width 100%; ${mq(
      props.theme.breakpointUtils.map,
      'sm',
    )} {flex-basis: 60%;}`};
`

export const AccordionItemContent = styled(SafeHtml)<{
  contentPadding?: boolean
}>`
  ${Text('bodyText', 'default')};
  margin: ${spacing(1)} auto;
  padding: ${(props) =>
    props.contentPadding ? `0 ${spacing(1)} ${spacing(2)}` : '0'};
`

export const AccordionTitle = styled(SafeHtml)<{
  contentPadding?: boolean
  textStyle?: TextStyling
}>`
  ${(props) =>
    Text(
      props.textStyle?.entry || 'bodyText',
      props.textStyle?.style || 'alternate',
    )};
  text-align: left;
  padding: ${(props) => (props.contentPadding ? `0 ${spacing(1)} ` : '0')};

  & span {
    display: inline-block;
    background: ${(props) => props.theme.colors.palette.greys.darker};
    color: ${(props) => props.theme.colors.palette.greys.white};
  }
`
